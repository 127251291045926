import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}

export default function MoneyBackSVG({ fillColor = COLORS.foreground}: Props) {
  return (
    <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="28" cy="28" r="28" fill={fillColor} />
      <path
        d="M41.888 34.6489C41.426 38.3729 38.374 41.4249 34.65 41.8869C32.396 42.1669 30.296 41.5509 28.658 40.3469C27.72 39.6609 27.944 38.2049 29.064 37.8689C33.278 36.5949 36.596 33.2629 37.884 29.0489C38.22 27.9429 39.676 27.7189 40.362 28.6429C41.552 30.2949 42.168 32.3949 41.888 34.6489Z"
        fill={fillColor}
      />
      <path
        d="M25.186 14C19.012 14 14 19.012 14 25.186C14 31.36 19.012 36.372 25.186 36.372C31.36 36.372 36.372 31.36 36.372 25.186C36.358 19.012 31.36 14 25.186 14ZM23.87 23.618L27.244 24.794C28.462 25.228 29.05 26.082 29.05 27.398C29.05 28.91 27.846 30.156 26.376 30.156H26.25V30.226C26.25 30.8 25.774 31.276 25.2 31.276C24.626 31.276 24.15 30.8 24.15 30.226V30.142C22.596 30.072 21.35 28.77 21.35 27.146C21.35 26.572 21.826 26.096 22.4 26.096C22.974 26.096 23.45 26.572 23.45 27.146C23.45 27.65 23.814 28.056 24.262 28.056H26.362C26.684 28.056 26.936 27.762 26.936 27.398C26.936 26.908 26.852 26.88 26.53 26.768L23.156 25.592C21.952 25.172 21.35 24.318 21.35 22.988C21.35 21.476 22.554 20.23 24.024 20.23H24.15V20.174C24.15 19.6 24.626 19.124 25.2 19.124C25.774 19.124 26.25 19.6 26.25 20.174V20.258C27.804 20.328 29.05 21.63 29.05 23.254C29.05 23.828 28.574 24.304 28 24.304C27.426 24.304 26.95 23.828 26.95 23.254C26.95 22.75 26.586 22.344 26.138 22.344H24.038C23.716 22.344 23.464 22.638 23.464 23.002C23.45 23.478 23.534 23.506 23.87 23.618Z"
        fill={fillColor}
      />
    </svg>
  );
}
